import {
  Game,
  GamePiece,
  GamePieceInteraction,
  GamePieceInteractionType,
  ID
} from "./game";
import { Emojidata, Emojis, Symbols } from "./emojidata";
import {
  ArgumentType,
  InvokableMutator,
  mp,
  Mutation,
  MutationAction,
  MutationActions,
  MutationContext,
  MutationPath
} from "./mutations";
import { Serializable, SerializableKeyValue } from "./serializable";
import { Vector2 } from "./vector2";

export interface TileInfo {
  emoji: Emojidata;
  mutator?: string;
  reusable: boolean;
}

export type Mutator = {
  method: (
    game: Game,
    ctx: MutationContext
  ) => {
    mutations: Mutation<any>[];
    stop?: boolean;
  };
};

export class PlayableTile extends Serializable {
  constructor(public id: string, public value?: number) {
    super([...arguments], ["id", "value"]);
  }

  get args(): { [key: string]: ArgumentType } {
    return this.mutator.args;
  }

  get info(): TileInfo {
    return Tiles[this.id as keyof typeof Tiles];
  }

  get mutator() {
    const mutatorId = this.info.mutator || this.id;
    return InvokeableMutators[mutatorId];
  }

  public mutate(game: Game, ctx: MutationContext) {
    const mutator = this.mutator;
    return mutator.invoke(game, ctx);
  }
}

export const Tiles = {
  NoOp: {
    emoji: Emojis.WhiteBoxLarge,
    reusable: true
  } as TileInfo,
  CurveRight: {
    emoji: Symbols.CurveRight,
    reusable: true
  } as TileInfo,
  CurveLeft: {
    emoji: Symbols.CurveLeft,
    reusable: true
  } as TileInfo,
  EndTurn: {
    emoji: Symbols.EndTurnTile,
    reusable: true
  } as TileInfo,
  Reset: {
    emoji: Symbols.ResetTile,
    reusable: true
  } as TileInfo,
  ConsumeTokens: {
    emoji: Symbols.ConsumeTokens,
    reusable: true
  } as TileInfo,
  ConsumeDiceRoll: {
    emoji: Symbols.ConsumeDiceRoll,
    reusable: true
  } as TileInfo,
  Draw: {
    emoji: Symbols.DrawTile,
    reusable: true
  } as TileInfo,
  MoveUp: {
    emoji: Symbols.UpTile,
    reusable: true
  } as TileInfo,
  MoveRight: {
    emoji: Symbols.RightTile,
    reusable: true
  } as TileInfo,
  MoveDown: {
    emoji: Symbols.DownTile,
    reusable: true
  } as TileInfo,
  MoveLeft: {
    emoji: Symbols.LeftTile,
    reusable: true
  } as TileInfo,
  Kick: {
    emoji: Symbols.KickTile,
    reusable: true
  } as TileInfo,

  ...[1, 2, 3, 4, 5, 6].reduce(
    (objs, num) => {
      objs[`DiceRoll_${num}`] = {
        emoji: Symbols[`DiceRoll_${num}`],
        reusable: true
      };
      return objs;
    },
    {} as { [s: string]: TileInfo }
  )
};

export const InvokeableMutators: { [id: string]: InvokableMutator<any> } = [
  /*
    Internal only mutators - only used for game operations
   */
  new InvokableMutator("NoOp", {}, (game, ctx) => {
    return { mutations: [] };
  }),
  new InvokableMutator("Reset", {}, (game, ctx) => {
    const ballPosition = new Vector2(5, 6);
    const ballRelativePositions = [
      new Vector2(0, 2),
      new Vector2(2, 3),
      new Vector2(-2, 3)
    ];

    const mutations: Mutation<any>[] = [
      /*
        TODO: Figure out why we can't just set ballPosition (it fails on kick into goal)
          it looks like it's broken because setting a non-serialized object doesn't get properly
          objectified which results in a single, non-objectified element in the tree
       */
      new Mutation(mp`ball/xy`, "Set", ballPosition)
    ];

    game.teams.toArray().forEach((team, teamIndex) => {
      const op = teamIndex === 1 ? "minus" : "plus";
      team.players
        .toArray()
        .forEach((player, playerIndex) =>
          mutations.push(
            new Mutation(
              mp`teams/${teamIndex}/players/${playerIndex}/xy`,
              "Set",
              ballPosition[op](ballRelativePositions[playerIndex])
            )
          )
        );
    });

    return {
      mutations
    };
  }),
  new InvokableMutator(
    "ScoreGoal",
    { p: ArgumentType.Required, v: ArgumentType.Required },
    (game, ctx) => {
      const rCtx = {
        ...ctx,
        args: {}
      };
      return {
        stop: true,
        mutations: [
          ...InvokeableMutators.Reset.invoke(game, rCtx).mutations,
          new Mutation(
            new MutationPath(`meta/score/${ctx.piece.extras["team"]}`),
            "Add",
            1
          )
        ]
      };
    }
  ),
  new InvokableMutator(
    "ConsumeTokens",
    { t: ArgumentType.Required },
    (game, ctx) => {
      const tokens = parseInt(ctx.args.t);
      const mutations = [];

      if (tokens <= game.teams.get(ctx.team).token_balance) {
        mutations.push(
          new Mutation(mp`teams/${ctx.team}/token_balance`, "Sub", tokens)
        );
      } else {
        return { mutations: [], stop: true };
      }

      return {
        mutations
      };
    }
  ),
  new InvokableMutator(
    "ConsumeDiceRoll",
    { v: ArgumentType.Required },
    (game, ctx) => {
      const value = parseInt(ctx.args.v);
      const hand = game.teams.get(ctx.team).hand.toArray();
      const indexes = hand
        .map((pt, index) => (`DiceRoll_${value}` == pt.id ? index : undefined))
        .filter(v => v);

      if (indexes.length == 0) {
        return { stop: true, mutations: [] };
      } else {
        return {
          mutations: [
            new Mutation(mp`teams/${ctx.team}/hand`, "Del", indexes[0])
          ]
        };
      }
    }
  ),

  /*
    Playable (Tile) mutators - invoked by and dealt to players
  */
  ...([
    {
      id: "Up",
      action: "Sub",
      direction: "y"
    },
    {
      id: "Right",
      action: "Add",
      direction: "x"
    },
    {
      id: "Down",
      action: "Add",
      direction: "y"
    },
    {
      id: "Left",
      action: "Sub",
      direction: "x"
    }
  ] as { id: string; action: MutationAction; direction: "x" | "y" }[]).map(
    td =>
      new InvokableMutator(
        `Move${td.id}`,
        { p: ArgumentType.Required, v: ArgumentType.Required },
        (game, ctx) => {
          const consumeTokens = InvokeableMutators.ConsumeTokens.invoke(game, {
            ...ctx,
            args: { t: 1 }
          });
          if (consumeTokens.stop) return consumeTokens;

          const consumeDiceRoll = InvokeableMutators.ConsumeDiceRoll.invoke(
            game,
            {
              ...ctx,
              args: { v: ctx.args.v }
            }
          );
          if (consumeDiceRoll.stop) return consumeDiceRoll;

          const player = game.teams
            .get(ctx.team)
            .getPlayerIndexByID(ctx.args.p);

          return {
            mutations: [
              ...consumeTokens.mutations,
              ...consumeDiceRoll.mutations,
              ...Move(
                td.action,
                `teams/${ctx.team}/players/${player}`,
                td.direction,
                `${td.id}Tile`,
                game,
                ctx
              )
            ]
          };
        }
      )
  ),

  ...["Left", "Right"].map(
    dir =>
      new InvokableMutator(
        `Curve${dir}`,
        { p: ArgumentType.Required },
        (game, ctx) => {
          return {
            mutations: Curve(
              dir.toLowerCase() as "left" | "right",
              `Curve${dir}`,
              game,
              ctx
            )
          };
        }
      )
  ),

  new InvokableMutator(
    "Kick",
    { p: ArgumentType.Required, v: ArgumentType.Required },
    (game, ctx) => {
      const consumeTokens = InvokeableMutators.ConsumeTokens.invoke(game, {
        ...ctx,
        args: { t: 1 }
      });
      if (consumeTokens.stop) return consumeTokens;

      const ball = game.ball;
      const playerIndex = game.teams
        .get(ctx.team)
        .getPlayerIndexByID(ctx.args.p);
      const player = game.teams.get(ctx.team).players.get(playerIndex);
      const distanceToBall = ball.xy.minus(player.xy);
      const isAdjacent = getAdjacentIndex(distanceToBall) != -1;

      if (!isAdjacent) return { mutations: [] };

      const mutations = [...consumeTokens.mutations];
      if (distanceToBall.y == -1) {
        mutations.push(...Move("Sub", "ball", "y", "UpTile", game, ctx));
      } else if (distanceToBall.x == 1) {
        mutations.push(...Move("Add", "ball", "x", "RightTile", game, ctx));
      } else if (distanceToBall.y == 1) {
        mutations.push(...Move("Add", "ball", "y", "DownTile", game, ctx));
      } else if (distanceToBall.x == -1) {
        mutations.push(...Move("Sub", "ball", "x", "LeftTile", game, ctx));
      }

      return { mutations };
    }
  ),
  new InvokableMutator("Draw", { r: ArgumentType.Server }, (game, ctx) => {
    const consumeTokens = InvokeableMutators.ConsumeTokens.invoke(game, {
      ...ctx,
      args: { t: 1 }
    });
    if (consumeTokens.stop) return consumeTokens;

    const team = game.teams.get(ctx.team);
    const randnum = parseFloat(ctx.args.r);
    const sack = TileSacks[team.sack];

    const mutations = [...consumeTokens.mutations];

    mutations.push(...sack(game, ctx, randnum));

    return { mutations };
  }),
  new InvokableMutator("EndTurn", {}, (game, ctx) => {
    const tokens = game.getValueForMutationPath(mp`meta/rules/tokens`);
    const mutations = [
      new Mutation(
        mp`teams/${(ctx.team + 1) % 2}/token_balance`,
        "Set",
        tokens
      ),
      new Mutation(mp`meta/turns`, "Add", 1)
    ];
    return { mutations };
  })
].reduce(
  (obj, entry) => {
    obj[entry.id] = entry;
    return obj;
  },
  {} as { [id: string]: InvokableMutator<any> }
);

export const TileSacks = {
  OnlyKicks: (game: Game, ctx: MutationContext, randomness: number) => {
    return [
      new Mutation(
        new MutationPath(`teams/${ctx.team}/hand`),
        "App",
        new PlayableTile("Kick", Math.ceil(randomness * 6))
      )
    ];
  },
  Regular: (game: Game, ctx: MutationContext, randomness: number) => {
    const tiles = ["Kick", "MoveUp", "MoveDown", "MoveLeft", "MoveRight"];

    return [
      new Mutation(
        new MutationPath(`teams/${ctx.team}/hand`),
        "App",
        new PlayableTile(
          tiles[Math.floor(randomness * tiles.length)],
          Math.ceil(randomness * 3)
        )
      )
    ];
  },
  DieRolls: (game: Game, ctx: MutationContext, randomness: number) => {
    const hand = game.teams.get(ctx.team).hand.toArray();
    const tiles = [1, 2, 3, 4, 5, 6].map(n => `DiceRoll_${n}`).filter(dr => {
      return !hand.filter(pt => pt.id == dr).length;
    });

    const drawn = tiles[Math.floor(randomness * tiles.length)];
    return tiles.length
      ? [
          new Mutation(
            new MutationPath(`teams/${ctx.team}/hand`),
            "App",
            new PlayableTile(drawn, parseInt(drawn.split("_")[1]))
          )
        ]
      : [];
  }
};

export function Curve(
  direction: "left" | "right",
  symbol: keyof typeof Symbols,
  game: Game,
  ctx: MutationContext
) {
  const ball = game.ball;
  const playerIndex = game.teams.get(ctx.team).getPlayerIndexByID(ctx.args.p);
  const player = game.teams.get(ctx.team).players.get(playerIndex);
  const distanceToBall = ball.xy.minus(player.xy);
  const index = getAdjacentIndex(distanceToBall);

  if (Math.abs(distanceToBall.x) > 1 || Math.abs(distanceToBall.y) > 1)
    return [];

  const mutations = [
    new Mutation(
      new MutationPath("distinctions"),
      "App",
      new GamePiece(
        ID(),
        symbol,
        player.xy.plus(
          ADJACENT_POSTIONS[getLoopedIndex(index, 0, ADJACENT_POSTIONS.length)]
        )
      )
    )
  ];

  let curvedIndex;

  switch (direction) {
    case "right":
      mutations.push(
        new Mutation(
          new MutationPath("distinctions"),
          "App",
          new GamePiece(
            ID(),
            symbol,
            player.xy.plus(
              ADJACENT_POSTIONS[
                getLoopedIndex(index, -1, ADJACENT_POSTIONS.length)
              ]
            )
          )
        )
      );
      curvedIndex = getLoopedIndex(index, -1, ADJACENT_POSTIONS.length);
      break;
    case "left":
      mutations.push(
        new Mutation(
          new MutationPath("distinctions"),
          "App",
          new GamePiece(
            ID(),
            symbol,
            player.xy.plus(
              ADJACENT_POSTIONS[
                getLoopedIndex(index, 1, ADJACENT_POSTIONS.length)
              ]
            )
          )
        )
      );
      curvedIndex = getLoopedIndex(index, 1, ADJACENT_POSTIONS.length);
      break;
  }
  const posDelta = ADJACENT_POSTIONS[curvedIndex];
  const position = new Vector2(
    player.xy.x + posDelta.x,
    player.xy.y + posDelta.y
  );

  for (const index in game.gamePieces) {
    const gamepiece = game.gamePieces[index];
    if (gamepiece.xy.equals(position)) {
      return [];
    }
  }

  return [
    ...mutations,
    new Mutation(new MutationPath("ball/xy"), "Set", position)
  ];
}

export function Move(
  action: MutationAction,
  piecePath: string,
  direction: "x" | "y",
  symbol: keyof typeof Symbols,
  game: Game,
  ctx: MutationContext
): Mutation<any>[] {
  const attemptedValue = parseInt(ctx.args.v);
  const hasValue = !!game.teams
    .get(ctx.team)
    .hand.toArray()
    .filter(tile => tile.value == attemptedValue).length;

  if (!hasValue) {
    return [];
  }

  const path = new MutationPath(`${piecePath}/xy/${direction}`);

  const pos = game.getObjectForMutationPath<Vector2>(
    new MutationPath(`${piecePath}/xy`)
  );

  const mutations: Mutation<any>[] = [
    new Mutation(mp`distinctions`, "App", new GamePiece(ID(), symbol, pos))
  ];

  const start = game.getValueForMutationPath(path);
  const pieceMask = game.getValueForMutationPath(mp`${piecePath}/layer`);

  let pushing = 0,
    p = 0,
    continued = true;

  const value = parseInt(ctx.args.v);
  while (continued && p < value + pushing) {
    p++;
    const v = MutationActions[action]({ a: [start], c: "", an: [] }, 0, p).a[0];

    if (v < 0) {
      p--;
      break;
    }

    let xy: Vector2;

    switch (direction) {
      case "y":
        xy = new Vector2(pos.x, v);
        break;
      case "x":
        xy = new Vector2(v, pos.y);
    }

    for (const index in game.gamePieces) {
      const gamepiece = game.gamePieces[index];
      let broken;

      if (gamepiece.xy.equals(xy)) {
        let interactions: SerializableKeyValue<GamePieceInteraction>[] = [];

        if (gamepiece.interactions) {
          interactions = gamepiece.interactions.toArray();
        }

        for (const kvInteractionIndex in interactions) {
          const kvInteraction = interactions[kvInteractionIndex];
          const interaction = kvInteraction.value;
          const mask = parseInt(kvInteraction.key.toString());

          if (game.meta.layers.isLayerMaskInMask(mask, pieceMask)) {
            if (
              interaction.interaction == GamePieceInteractionType.Triggerable
            ) {
              const mCtx = { ...ctx };
              mCtx.piece = gamepiece;
              const triggerMutations = InvokeableMutators[
                interaction.mutator
              ].invoke(game, mCtx);
              mutations.push(...triggerMutations.mutations);

              if (triggerMutations.stop) {
                continued = false;
                broken = true;
              }
            } else if (
              interaction.interaction == GamePieceInteractionType.Movable
            ) {
              pushing++;
            } else if (
              interaction.interaction == GamePieceInteractionType.Immovable
            ) {
              continued = false;
              p--;
              broken = true;
            }
          }
        }
      }

      if (broken) break;
    }

    mutations.push(
      new Mutation(mp`distinctions`, "App", new GamePiece(ID(), symbol, xy))
    );
  }
  mutations.push(new Mutation(path, action, p - pushing));

  if (pushing) {
    const ballPower = MutationActions[action](
      { a: [start], c: "", an: [] },
      0,
      p
    ).a[0];
    mutations.push(
      new Mutation(new MutationPath(`ball/xy/${direction}`), "Set", ballPower)
    );
  }

  return mutations;
}

export const ASCII_TO_NUMBER_OFFSET = 97;
export const ADJACENT_POSTIONS = [
  new Vector2(-1, 0), // Left
  // new Vector2(-1, 1), // Bottom Left corner
  new Vector2(0, 1), // Bottom
  // new Vector2(1, 1), // Bottom Right corner
  new Vector2(1, 0), // Right
  // new Vector2(1, -1), // Top Right corner
  new Vector2(0, -1) // Top
  // new Vector2(-1, -1) // Top Left corner
];

export function getAdjacentIndex(v2: Vector2) {
  const matchingPairs = ADJACENT_POSTIONS.map((xy, index) => {
    return { xy, index };
  }).filter(pair => pair.xy.equals(v2));

  if (matchingPairs.length) {
    return matchingPairs[0].index;
  } else {
    return -1;
  }
}

function getLoopedIndex(index: number, step: number, count: number) {
  let newIndex = index + step;

  if (newIndex < 0) {
    newIndex += count;
  } else if (newIndex >= count) {
    newIndex = newIndex % count;
  }

  return newIndex;
}

export function has(amount: number) {
  return <T>(value: T): T[] => {
    const arr = [];
    for (let i = 0; i < amount; i++) {
      arr.push(value);
    }
    return arr;
  };
}
