import { Objectification, Serializable } from "./serializable";
import { PlayableTile } from "./tiles";
import { Game, GameEnvironment, GamePiece } from "./game";

export interface MutationContext {
  team: number;
  piece?: GamePiece;
  tile?: PlayableTile;
  args: { [s: string]: string };
}

export type MutationAction = keyof typeof MutationActions;
export const MutationActions = {
  Add(obj: Objectification, field: string | number, value: any) {
    const key = Serializable.getArgumentIndex(obj, field);

    if (value.objectify) {
      value = value.objectify();
    }

    obj.a[key] += value;
    return obj;
  },
  Sub(obj: Objectification, field: string | number, value: any) {
    const key = Serializable.getArgumentIndex(obj, field);

    if (value.objectify) {
      value = value.objectify();
    }

    obj.a[key] -= value;
    return obj;
  },
  Del(obj: Objectification, field: string | number, value: any) {
    const key = Serializable.getArgumentIndex(obj, field);

    if (value.objectify) {
      value = value.objectify();
    }

    obj.a[key].a.splice(value, 1);
    return obj;
  },
  Set(obj: Objectification, field: string | number, value: any) {
    const key = Serializable.getArgumentIndex(obj, field);

    if (value.objectify) {
      value = value.objectify();
    }

    obj.a[key] = value;
    return obj;
  },
  App(obj: Objectification, field: string | number, value: any) {
    const key = Serializable.getArgumentIndex(obj, field);

    if (value.objectify) {
      value = value.objectify();
    }

    obj.a[key].a.push(value);
    return obj;
  }
};

const _mp_cache: { [path: string]: MutationPath } = {};

export function mp(chunks: TemplateStringsArray, ...args: (string | number)[]) {
  const path = chunks.reduce((p, chunk, index) => {
    return p + chunk + (args[index] === undefined ? "" : args[index]);
  }, "");

  if (!_mp_cache[path]) {
    _mp_cache[path] = new MutationPath(path);
  }
  return _mp_cache[path];
}

export type MutationHierarchy = {
  ids: (string | number)[];
  field: string | number;
};

export class MutationPath {
  constructor(public path: string) {}
  private _hierarchy: MutationHierarchy;

  get hierarchy() {
    if (!this._hierarchy) {
      const hierarchy = this.path.split("/");
      const ids = hierarchy.slice(0, -1).map(this.parseHierarchyName);
      this._hierarchy = {
        ids,
        field: this.parseHierarchyName(hierarchy.slice(-1)[0])
      };
    }
    return this._hierarchy;
  }

  private parseHierarchyName(name: string) {
    if (name.includes(":")) throw name;

    const num = parseInt(name);
    return num.toString() != "NaN" ? num : name;
  }
}

export class Mutation<T> {
  constructor(
    public path: MutationPath,
    public action: MutationAction,
    public value?: T
  ) {}
}

export enum ArgumentType {
  Required = "required",
  Server = "server",
  Optional = "optional"
}

export class InvokableMutator<T> {
  constructor(
    public id: string,
    public args: T,
    private method: (
      game: Game,
      ctx: {
        team: number;
        piece?: GamePiece;
        tile?: PlayableTile;
        args: T;
      }
    ) => {
      mutations: Mutation<any>[];
      stop?: boolean;
    }
  ) {}

  invoke(
    game: Game,
    ctx: {
      team: number;
      piece?: GamePiece;
      tile?: PlayableTile;
      args: { [key: string]: string | number };
    }
  ) {
    const implicitMutations = [];

    for (const arg in this.args) {
      if (!ctx.args[arg] && (this.args as any)[arg] == ArgumentType.Required) {
        throw `Cannot invoke mutator ${this.id}, Missing argument "${arg}"`;
      }

      if ((this.args as any)[arg] == ArgumentType.Server) {
        if (game.env !== GameEnvironment.Server)
          throw `Can't supply ArgumentType.Server "${arg}" when not on server.`;
        switch (arg) {
          case "r":
            const randoms = game.randomize(1);
            ctx.args["r"] = randoms.values[0].toString();
            implicitMutations.push(...randoms.mutations);
            break;
          default:
            throw `Unknown key for server supplied argument ${arg}`;
        }
      }
    }

    for (const arg in ctx.args) {
      if (!(this.args as any)[arg]) {
        throw `Cannot invoke mutator ${this.id}, Extra argument "${arg}"`;
      }
    }

    const methodResults = this.method(game, ctx as any);
    return {
      mutations: [...implicitMutations, ...methodResults.mutations],
      stop: methodResults.stop || false
    };
  }
}
