import { Vector2 } from "./vector2";
import { Emojidata, Emojis, Symbols } from "./emojidata";
import { GamePiece, GamePieceInteractionType, GameTeam } from "./game";
import { SerializableArray } from "./serializable";
import { PlayableTile } from "./tiles";

export type RenderablePiece = { xy: Vector2; emoji: Emojidata };

export class EmojiBoard {
  pieces: RenderablePiece[] = [];

  constructor(protected readonly dimensions: Vector2) {}

  toString(): string {
    const grid: string[][] = [];

    for (let y = 0; y < this.dimensions.y; y++) {
      const row = [];
      for (let x = 0; x < this.dimensions.x; x++) {
        row.push(Symbols.Blank.toString());
      }
      grid.push(row);
    }

    this.pieces.forEach(piece => {
      grid[piece.xy.y][piece.xy.x] = piece.emoji.toString();
    });

    return grid
      .map(row => {
        return row.join("");
      })
      .join("\n");
  }
}

export class Field extends EmojiBoard {
  constructor(
    protected readonly dimensions: Vector2,
    private readonly teams: SerializableArray<GameTeam>,
    private readonly gamePieces: GamePiece[]
  ) {
    super(dimensions);

    this.pieces = this.pieces.concat(
      gamePieces.map(piece => {
        if (piece instanceof GamePiece) {
          return { xy: piece.xy, emoji: Symbols[piece.symbol] };
        } else {
          return piece;
        }
      })
    );

    this.teams.toArray().forEach(team => {
      team.players.toArray().forEach(player => {
        this.pieces.push({
          emoji: team.mascot,
          xy: player.xy
        });
      });
    });
  }
}

export enum DashboardVisibility {
  Restricted,
  Regular,
  Full
}

export class Dashboard extends EmojiBoard {
  constructor(
    maxDimensions: Vector2,
    private readonly tiles: SerializableArray<PlayableTile>,
    private readonly opts: { visibility: DashboardVisibility } = {
      visibility: DashboardVisibility.Regular
    }
  ) {
    super(new Vector2(maxDimensions.x, 2));
    const offset = 0;

    this.tiles.toArray().forEach((pt, index) => {
      let powerEmoji;

      if (this.opts.visibility == DashboardVisibility.Regular && pt.value) {
        powerEmoji = Symbols.UnknownPower;
      }

      if (this.opts.visibility == DashboardVisibility.Restricted) {
        powerEmoji = Symbols.Restricted;
      }

      if (this.opts.visibility == DashboardVisibility.Full) {
        powerEmoji = Emojis[`KeycapDigit${pt.value}`];
      }

      if (powerEmoji) {
        this.pieces.push({
          xy: new Vector2(offset + index, 1),
          emoji: powerEmoji
        });
      }

      this.pieces.push({
        xy: new Vector2(offset + index, 0),
        emoji:
          this.opts.visibility == DashboardVisibility.Restricted
            ? Symbols.Restricted
            : pt.info.emoji
      });
    });
  }
}
