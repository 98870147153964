import {
  Objectification,
  ObjUtilities,
  Serializable,
  SerializableArray,
  SerializableKeyValue,
  SerializableMap
} from "./serializable";
import { Emojidata, Symbols } from "./emojidata";
import { PlayableTile, TileSacks } from "./tiles";
import { mp, Mutation, MutationActions, MutationPath } from "./mutations";
import { Randomizers } from "./random";
import { Vector2 } from "./vector2";
import { Dashboard, DashboardVisibility, Field } from "./emojiboard";
import { Command } from "./command";

export class GameHand extends SerializableArray<PlayableTile> {
  constructor(...items: PlayableTile[]) {
    super(...items);
  }

  getTileIndexByID(tileId: string): number {
    return this.toArray()
      .map((pt, index) => {
        if (pt.id == tileId) return index;
      })
      .filter(t => t)[0];
  }
}

export class GameTeam extends Serializable {
  constructor(
    public mascot: Emojidata,
    public hand: GameHand,
    public players: SerializableArray<GamePiece>,
    public token_balance: number,
    public sack: keyof typeof TileSacks,
    public next_command: string
  ) {
    super(
      [...arguments],
      ["mascot", "hand", "players", "token_balance", "sack", "next_command"]
    );
  }

  public getPlayerIndexByID(playerId: string) {
    let index = 0;
    for (const player of this.players.toArray()) {
      if (player.id.toLowerCase() == playerId.toLowerCase()) {
        return index;
      }
      index++;
    }
  }
}

export enum GamePieceInteractionType {
  Movable = "movable",
  Immovable = "immovable",
  Triggerable = "triggerable",
  None = "none"
}

export class GamePieceInteraction extends Serializable {
  constructor(
    public interaction: GamePieceInteractionType,
    public mutator?: string
  ) {
    super([...arguments], ["interaction", "mutator"]);
  }
}

export class GamePieceLayerSet extends SerializableArray<string> {
  private static readonly _cache: { [key: string]: number } = {};
  constructor(...items: string[]) {
    super(...items);
  }

  getMaskForAllLayers(exclude: string[] = []) {
    return this.getMaskForLayerNames(this.toArray(), exclude);
  }

  getMaskForLayerNames(names: string[], exclude: string[] = []) {
    const cacheKey = names.toString() + "|" + exclude.toString();

    if (!GamePieceLayerSet._cache[cacheKey]) {
      GamePieceLayerSet._cache[cacheKey] = names.reduce((mask, name) => {
        const index = this.toArray().indexOf(name);
        if (index == -1)
          throw `Can't find layer "${name}" in layer set [${this.toArray().join(
            ", "
          )}]`;

        if (exclude.indexOf(name) == -1) {
          mask = mask | Math.pow(2, index);
        }

        return mask;
      }, 0);
    }

    return GamePieceLayerSet._cache[cacheKey];
  }

  getMaskForLayerName(name: string) {
    return this.getMaskForLayerNames([name]);
  }

  isLayerMaskInMask(mask: number, layerMask: number) {
    return !!(mask & layerMask);
  }

  isLayerNameInMask(mask: number, layerName: string) {
    return this.isLayerMaskInMask(mask, this.getMaskForLayerName(layerName));
  }
}

export class GamePiece extends Serializable {
  constructor(
    public id: string,
    public symbol: keyof typeof Symbols,
    public xy: Vector2,
    public layer?: number,
    public interactions?: SerializableMap<GamePieceInteraction>,
    public extras?: any
  ) {
    super(
      [...arguments],
      ["id", "symbol", "xy", "layer", "interactions", "extras"]
    );
  }
}

export class GameRules extends Serializable {
  constructor(
    public readonly dimensions: Vector2,
    public readonly tokens: number
  ) {
    super([...arguments], ["dimensions", "tokens"]);
  }
}

export class GameRandomizer extends Serializable {
  constructor(
    public readonly seed: string,
    public readonly generator: keyof typeof Randomizers,
    public readonly iterations: number,
    public readonly value: number
  ) {
    super([...arguments], ["seed", "generator", "iterations", "value"]);
  }
}

export class GameMeta extends Serializable {
  constructor(
    public rules: GameRules,
    public random: GameRandomizer,
    public turns: number,
    public score: SerializableArray<number>,
    public layers: GamePieceLayerSet
  ) {
    super([...arguments], ["rules", "random", "turns", "score", "layers"]);
  }
}

export enum GameEnvironment {
  Server = "server",
  Client = "client"
}

export class Game extends Serializable {
  public readonly fieldPieces: GamePiece[] = [];
  public readonly _ephemeralPieces: GamePiece[] = [];

  get ephemeralPieces() {
    if (!this._ephemeralPieces.length) {
      const dimensions = this.meta.rules.dimensions;
      const goalPositions: Vector2[] = [];

      let goals = 3;
      if (dimensions.x % 2 == 0) {
        goals++;
      }

      const goalOffset = Math.floor((dimensions.x - goals) / 2);

      [0, dimensions.y - 1].forEach(y => {
        for (let x = 0; x < goals; x++) {
          const xy = new Vector2(goalOffset + x, y);
          goalPositions.push(xy);
        }
      });

      dimensions.forEach(xy => {
        if (
          xy.y == 0 ||
          xy.y == dimensions.y - 1 ||
          xy.x == 0 ||
          xy.x == dimensions.x - 1
        ) {
          if (
            !goalPositions.filter(gp => gp.x == xy.x && gp.y == xy.y).length
          ) {
            this._ephemeralPieces.push(
              new GamePiece(
                `field-${xy.x}-${xy.y}`,
                "FieldBorder",
                xy,
                this.meta.layers.getMaskForLayerName("walls"),
                new SerializableMap(
                  new SerializableKeyValue(
                    this.meta.layers.getMaskForAllLayers(),
                    new GamePieceInteraction(GamePieceInteractionType.Immovable)
                  )
                )
              )
            );
          } else {
            this._ephemeralPieces.push(
              new GamePiece(
                `goal-${xy.x}-${xy.y}`,
                "Goal",
                xy,
                this.meta.layers.getMaskForLayerName("goals"),
                new SerializableMap(
                  new SerializableKeyValue(
                    this.meta.layers.getMaskForLayerName("balls"),
                    new GamePieceInteraction(
                      GamePieceInteractionType.Triggerable,
                      "ScoreGoal"
                    )
                  ),
                  new SerializableKeyValue(
                    this.meta.layers.getMaskForAllLayers(["balls"]),
                    new GamePieceInteraction(GamePieceInteractionType.Immovable)
                  )
                ),
                {
                  team: xy.y == 0 ? 0 : 1
                }
              )
            );
          }
        }
      });
    }

    return this._ephemeralPieces;
  }

  constructor(
    public readonly env: GameEnvironment,
    public readonly version: string,
    public readonly meta: GameMeta,
    public readonly teams: SerializableArray<GameTeam>,
    public readonly ball: GamePiece,
    public readonly distinctions: SerializableArray<GamePiece>
  ) {
    super(
      [...arguments],
      ["env", "version", "meta", "teams", "ball", "distinctions"]
    );
  }

  getMutationsForAdvancement(
    commandsByTeam: string[]
  ): Mutation<any>[] | boolean {
    const activeTeamIndex = this.meta.turns % 2;
    const otherTeamIndex = (this.meta.turns + 1) % 2;

    if (commandsByTeam[otherTeamIndex].length) {
      console.log("Rejected inactive team's attempt to play.");
      return false;
    }

    const commandStr = commandsByTeam[activeTeamIndex];
    const team = this.teams.get(activeTeamIndex);
    const hand = team.hand;
    const commands = Command.Parse(commandStr);

    if (commands.length > 1) {
      throw "Rejected attempt to advance with multiple commands. This is no longer supported.";
      return false;
    }

    if (team.next_command.length) {
      const command = commands[0];

      if (command.toString() !== team.next_command) {
        console.log(
          `Rejected attempted command "${command.toString()}" when next command is "${
            team.next_command
          }"`
        );
        return false;
      }
    }

    const tileRemovalMutations = commands
      .filter(cmd => !cmd.tile.info.reusable)
      .map(cmd => hand.getTileIndexByID(cmd.id))
      .sort()
      .map((index, i) => {
        const adjustedIndex = index - i;
        return new Mutation(
          mp`teams/${activeTeamIndex}/hand`,
          "Del",
          adjustedIndex
        );
      });

    let success = true;
    const mutations: Mutation<any>[] = [];
    commands.forEach(cmd => {
      const ctx = {
        args: cmd.args,
        team: activeTeamIndex,
        tile: cmd.tile
      };

      const result = cmd.tile.mutate(this, ctx);
      if (mutations) {
        mutations.push(...result.mutations);
      } else {
        console.log("Command was rejected");
        success = false;
      }
    });

    if (!success) return false;

    return [
      new Mutation(mp`teams/${activeTeamIndex}/next_command`, "Set", ""),
      ...tileRemovalMutations,
      ...mutations
    ];
  }

  advance(commandsByTeam: string[]): Game {
    const mutations = this.getMutationsForAdvancement(commandsByTeam);
    if (typeof mutations !== "boolean") {
      return this.clone(mutations);
    } else {
      return this;
    }
  }

  randomize(count = 1): { mutations: Mutation<any>[]; values: number[] } {
    if (count > 0) {
      const randomizer = this.meta.random;
      const r = Randomizers[randomizer.generator];
      const values = [];

      for (let i = 0; i < count; i++) {
        values.push(r(randomizer.seed, randomizer.iterations + i));
      }

      return {
        values: values,
        mutations: [
          new Mutation(
            new MutationPath("meta/random/value"),
            "Set",
            values.slice(-1)[0]
          ),
          new Mutation(new MutationPath("meta/random/iterations"), "Add", count)
        ]
      };
    } else {
      return { values: [], mutations: [] };
    }
  }

  toString() {
    return [
      new Dashboard(this.meta.rules.dimensions, this.teams.toArray()[0].hand, {
        visibility: DashboardVisibility.Restricted
      }).toString(),
      new Field(this.meta.rules.dimensions, this.teams, [
        ...this.distinctions.toArray(),
        ...this.gamePieces
      ]).toString(),
      new Dashboard(
        this.meta.rules.dimensions,
        this.teams.toArray()[1].hand
      ).toString()
    ].join("\n");
  }

  clone(mutations: Mutation<any>[] = []) {
    return Serializable.deserialize<Game>([
      ObjUtilities.replicate(this.objectify(), mutations)
    ]);
  }

  getObjectForMutationPath<T>(path: MutationPath): T {
    const obj = this.getSerializedForMutationPath(path);

    const id = Serializable.getArgumentIndex(obj, path.hierarchy.field);
    return Serializable.deserialize([obj.a[id]]) as T;
  }

  getValueForMutationPath(path: MutationPath) {
    const obj = this.getSerializedForMutationPath(path);
    const id = Serializable.getArgumentIndex(obj, path.hierarchy.field);

    return obj.a[id];
  }

  private getSerializedForMutationPath(path: MutationPath, serialized?: any) {
    if (!serialized) {
      serialized = this.objectify();
    }

    const obj = path.hierarchy.ids.reduce((obj: Objectification, key) => {
      const id = Serializable.getArgumentIndex(obj, key.toString());
      return obj.a[id];
    }, serialized);

    return obj;
  }

  get gamePieces(): GamePiece[] {
    return [
      ...this.ephemeralPieces,
      ...this.teams.get(0).players.toArray(),
      ...this.teams.get(1).players.toArray(),
      ...this.fieldPieces,
      this.ball
    ];
  }
}

export function ID() {
  return Math.random()
    .toString(36)
    .slice(-8);
}
