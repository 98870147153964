import {
  Game,
  GameEnvironment,
  GameHand,
  GameMeta,
  GamePiece,
  GamePieceInteraction,
  GamePieceLayerSet,
  GameRandomizer,
  GameRules,
  GameTeam
} from "../engine/game";
import { Vector2 } from "../engine/vector2";
import { PlayableTile } from "../engine/tiles";
import {
  known_types,
  Serializable,
  SerializableArray,
  SerializableKeyValue,
  SerializableMap
} from "../engine/serializable";
import { Command } from "../engine/command";
import { Mutation, MutationPath } from "../engine/mutations";

const kt = known_types;
kt["Game"] = Game;
kt["GamePiece"] = GamePiece;
kt["GameRules"] = GameRules;
kt["GameMeta"] = GameMeta;
kt["GameTeam"] = GameTeam;
kt["GameHand"] = GameHand;
kt["GameRandomizer"] = GameRandomizer;
kt["GamePieceLayerSet"] = GamePieceLayerSet;
kt["GamePieceInteraction"] = GamePieceInteraction;

kt["Vector2"] = Vector2;
kt["PlayableTile"] = PlayableTile;
kt["SerializableArray"] = SerializableArray;
kt["Serializable"] = Serializable;
kt["SerializableMap"] = SerializableMap;
kt["SerializableKeyValue"] = SerializableKeyValue;

const W = window as any;
W.Game = Game;
W.Command = Command;
W.Mutation = Mutation;
W.MutationPath = MutationPath;
W.Runtime_Package = { version: require("../package.json").version };
