import { PlayableTile, Tiles } from "./tiles";

const cmdParsingRegex = /([^\[\]]+)(?:\[(.+?)\])?,?/g;

export class Command {
  public tile: PlayableTile;
  constructor(
    public id: keyof typeof Tiles,
    public args: { [s: string]: string }
  ) {
    this.tile = new PlayableTile(this.id);

    if (!this.tile.info) {
      throw `Command reference non-existent tile "${this.id}"`;
    }
  }

  toString() {
    return `${this.id}[${Object.keys(this.args)
      .map(key => `${key}:${this.args[key]}`)
      .join(",")}]`;
  }

  public static Parse(string: string): Command[] {
    const commands = [];
    let cmdMatch;
    while ((cmdMatch = cmdParsingRegex.exec(string)) !== null) {
      const [_, tile_id, argString] = cmdMatch;
      let args = {};

      if (argString) {
        args = argString.split(",").reduce(
          (bundle, str) => {
            const [key, value] = str.split(":");
            bundle[key] = value;
            return bundle;
          },
          {} as { [s: string]: string }
        );
      }

      commands.push(new Command(tile_id as keyof typeof Tiles, args));
    }
    return commands;
  }
}
