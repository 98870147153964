import { Serializable } from "./serializable";

export class Vector2 extends Serializable {
  constructor(public readonly x: number, public readonly y: number) {
    super([...arguments], ["x", "y"]);
  }

  toString(): string {
    return `${this.x}x${this.y}`;
  }

  forEach(fn: (xy: Vector2) => void) {
    for (let y = 0; y < this.y; y++) {
      for (let x = 0; x < this.x; x++) {
        fn(new Vector2(x, y));
      }
    }
  }

  equals(v2: Vector2): boolean {
    return v2.x == this.x && v2.y == this.y;
  }

  minus(v2: Vector2): Vector2 {
    return new Vector2(this.x - v2.x, this.y - v2.y);
  }

  plus(v2: Vector2): Vector2 {
    return new Vector2(this.x + v2.x, this.y + v2.y);
  }

  times(v2: Vector2): Vector2 {
    return new Vector2(this.x * v2.x, this.y * v2.y);
  }

  distanceTo(other: Vector2): number {
    const dx = this.x - other.x;
    const dy = this.y - other.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    return Math.abs(dist);
  }

  static Parse(xy: string) {
    const [x, y] = xy.split("x");
    return new Vector2(parseInt(x), parseInt(y));
  }
}
