import { Mutation, MutationActions, MutationPath } from "./mutations";

const { inflate, deflate } = require("pako");
export const known_types: { [s: string]: Function } = {};

export type Objectification = {
  c: string; // Class Name
  a: any[]; // Args
  an: string[]; // Arg Names
};

export class ObjUtilities {
  static getDeserializedforMutationPath<T>(
    root: Objectification,
    path: MutationPath
  ) {
    const obj = this.getObjForMutationPath(root, path);
    return Serializable.deserialize<T>([obj]);
  }

  static getObjForMutationPath(root: Objectification, path: MutationPath) {
    const obj = this.getSerializedForMutationPath(root, path);
    const id = Serializable.getArgumentIndex(obj, path.hierarchy.field);
    return obj.a[id];
  }

  static replicate(root: Objectification, mutations: Mutation<any>[]) {
    const root_clone = JSON.parse(JSON.stringify(root));
    mutations.forEach(mutation => {
      const hierarchy = mutation.path.hierarchy;
      const obj = this.getSerializedForMutationPath(root_clone, mutation.path);

      try {
        MutationActions[mutation.action](obj, hierarchy.field, mutation.value);
      } catch (err) {
        console.warn("Mutation failed:", mutation);
        throw err;
      }
    });

    return root_clone;
  }

  private static getSerializedForMutationPath(
    obj: Objectification,
    path: MutationPath
  ) {
    return path.hierarchy.ids.reduce((obj: Objectification, key) => {
      const id = Serializable.getArgumentIndex(obj, key.toString());
      return obj.a[id];
    }, obj);
  }
}

export class Serializable {
  private readonly _args: any[];
  private readonly _argNames: string[];

  constructor(args: any[], argNames: any[] = []) {
    this._args = args;
    this._argNames = argNames;
    known_types[this.constructor.name] = this.constructor;
  }

  objectify(): Objectification {
    const serializedArgs: any[] = this._args.map(arg => {
      if (arg instanceof Serializable) {
        return arg.objectify();
      } else {
        return arg;
      }
    });

    const obj = {
      c: this.constructor.name,
      a: serializedArgs,
      an: this._argNames
    };
    return obj;
  }

  serialize(
    options: { pretty?: boolean; zipped?: boolean } = {
      pretty: false,
      zipped: false
    }
  ): string {
    const recipe = this.objectify();
    let serialized;

    if (options.pretty) {
      serialized = JSON.stringify(recipe, undefined, 2);
    } else {
      serialized = JSON.stringify(recipe);
    }

    if (options.zipped) {
      serialized = Zipper.zip(serialized);
    }

    return serialized;
  }

  static deserialize<T>(
    fragments: (Objectification | string)[],
    options: { zipped?: boolean } = { zipped: false }
  ): T {
    const objectifications = fragments.map(fragment => {
      let serialized;
      if (typeof fragment == "string") {
        if (options.zipped) {
          serialized = Zipper.unzip(fragment);
        } else {
          serialized = fragment;
        }
      } else {
        return fragment;
      }

      return JSON.parse(serialized);
    });

    const obj = objectifications[0];
    const args = obj.a.map((arg: any) => {
      if (arg["c"]) {
        return Serializable.deserialize([arg]);
      } else {
        return arg;
      }
    });

    try {
      const c = new (known_types as any)[obj.c](...args);
      return c;
    } catch (err) {
      console.warn(`Can't deserialize ${obj.c}`);
      throw err;
    }
  }

  static getArgumentIndex(obj: Objectification, key: string | number): number {
    const index = (obj.an || []).indexOf(key.toString());
    const numKey = parseInt(key.toString());

    if (index == -1 && !isNaN(numKey)) return numKey;
    else if (index !== -1) return index;
    else
      throw `Can't handle getArgumentIndex for ${JSON.stringify(
        obj
      )} of ${key}`;
  }
}

export class SerializableArray<T> extends Serializable {
  items: T[];

  constructor(...items: T[]) {
    super([...arguments]);
    this.items = items;
  }

  get(index: number): T {
    return this.items[index];
  }

  toArray(): T[] {
    return this.items;
  }
}

export class SerializableMap<T> extends Serializable {
  items: SerializableKeyValue<T>[];

  constructor(...items: SerializableKeyValue<T>[]) {
    super(items, items.map(kv => kv.key.toString()));
    this.items = items;
  }

  toArray(): SerializableKeyValue<T>[] {
    return this.items;
  }
}

export class SerializableKeyValue<T> extends Serializable {
  constructor(public readonly key: string | number, public readonly value: T) {
    super([...arguments], ["key", "value"]);
  }
}

class Zipper {
  private static browser_implementation = {
    unzip(base64: string) {
      const raw = window.atob(base64);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));
      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }

      return String.fromCharCode.apply(undefined, inflate(array));
    },
    zip(base64: string) {
      throw `Zipper.zip is not implemented in the browser`;
    }
  };

  private static node_implementation = {
    unzip(base64: string) {
      return Buffer.from(inflate(Buffer.from(base64, "base64"))).toString();
    },
    zip(data: string): string {
      return Buffer.from(deflate(data)).toString("base64");
    }
  };

  static unzip(base64: string): string {
    if (typeof Buffer !== "undefined") {
      return this.node_implementation.unzip(base64);
    } else {
      return this.browser_implementation.unzip(base64);
    }
  }

  static zip(base64: string): string {
    if (typeof Buffer !== "undefined") {
      return this.node_implementation.zip(base64);
    } else {
      return this.browser_implementation.zip(base64);
    }
  }
}
