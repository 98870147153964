import { Serializable } from "./serializable";

export class Emojidata extends Serializable {
  constructor(
    private readonly characters: string,
    private readonly terminalFix?: string
  ) {
    super([...arguments], ["characters", "terminalFix"]);
  }

  toString() {
    return this.characters + (this.terminalFix || "");
  }
}
export const Emojis: { [s: string]: Emojidata } = {
  Woman: new Emojidata("👩‍"),
  Restricted: new Emojidata("🈲"),
  WhiteSquareLarge: new Emojidata("⬜"),
  BlackSquareLarge: new Emojidata("⬛"),
  RoundedTree: new Emojidata("🌳"),
  Goal: new Emojidata("🥅"),
  Sheep: new Emojidata("🐏"),
  Fox: new Emojidata("🦊F"),
  SoccerBall: new Emojidata("⚽"),
  Pig: new Emojidata("🐖"),
  HeavyPlusSign: new Emojidata("➕"),
  UpArrow: new Emojidata("⬆️"),
  DownArrow: new Emojidata("⬇️"),
  LeftArrow: new Emojidata("⬅️"),
  RightArrow: new Emojidata("➡️"),
  ReverseButton: new Emojidata("◀️", " "),
  PlayButton: new Emojidata("▶️", " "),
  FastForwardButton: new Emojidata("⏩"),
  FastReverseButton: new Emojidata("⏪", " "),
  UpwardsButton: new Emojidata("🔼", " "),
  FastUpButton: new Emojidata("⏫", " "),
  DownwardsButton: new Emojidata("🔽", " "),
  FastDownButton: new Emojidata("⏬", " "),
  VsButton: new Emojidata("🆚"),
  Warning: new Emojidata("⚠️"),
  AtomSymbol: new Emojidata("⚛️", " "),
  HighVoltageSign: new Emojidata("⚡"),
  KeycapDigit0: new Emojidata("0️⃣", " "),
  KeycapDigit1: new Emojidata("1️⃣", " "),
  KeycapDigit2: new Emojidata("2️⃣", " "),
  KeycapDigit3: new Emojidata("3️⃣", " "),
  KeycapDigit4: new Emojidata("4️⃣", " "),
  KeycapDigit5: new Emojidata("5️⃣", " "),
  KeycapDigit6: new Emojidata("6️⃣", " "),
  KeycapDigit7: new Emojidata("7️⃣", " "),
  KeycapDigit8: new Emojidata("8️⃣", " "),
  KeycapDigit9: new Emojidata("9️⃣", " "),
  CrossMarkButton: new Emojidata("❎"),
  RightArrowCurvingLeft: new Emojidata("↩️"),
  LeftArrowCurvingRight: new Emojidata("↪️"),
  OButton: new Emojidata("🅾️"),
  OKButton: new Emojidata("🆗"),
  JokerCard: new Emojidata("🃏")
};

export const Symbols: { [s: string]: Emojidata } = {
  Ball: Emojis.SoccerBall,
  Player: Emojis.Woman,
  Restricted: Emojis.Restricted,
  Blank: Emojis.BlackSquareLarge,
  FieldBorder: Emojis.RoundedTree,
  Goal: Emojis.Goal,
  LeftTile: Emojis.LeftArrow,
  DoubleLeftTile: Emojis.FastReverseButton,
  RightTile: Emojis.RightArrow,
  DoubleRightTile: Emojis.FastForwardButton,
  UpTile: Emojis.UpArrow,
  DoubleUpTile: Emojis.FastUpButton,
  DownTile: Emojis.DownArrow,
  DoubleDownTile: Emojis.FastDownButton,
  UnknownTile: Emojis.Warning,
  UnknownPower: Emojis.Warning,
  WisdomPowerTile: Emojis.AtomSymbol,
  CurveRight: Emojis.RightArrowCurvingLeft,
  CurveLeft: Emojis.LeftArrowCurvingRight,
  KickTile: Emojis.OButton,
  EndTurnTile: Emojis.OKButton,
  DrawTile: Emojis.JokerCard,
  DiceRoll_1: Emojis.KeycapDigit1,
  DiceRoll_2: Emojis.KeycapDigit2,
  DiceRoll_3: Emojis.KeycapDigit3,
  DiceRoll_4: Emojis.KeycapDigit4,
  DiceRoll_5: Emojis.KeycapDigit5,
  DiceRoll_6: Emojis.KeycapDigit6,

  /*
    Internal
   */
  ResetTile: Emojis.JokerCard,
  ConsumeTokens: Emojis.JokerCard,
  ConsumeDiceRoll: Emojis.JokerCard
};
